// import logo from './images/logo-blue.png';
import logo from './images/liaison-logo-orange.png';
import logoKey from './images/liaison-key-black.png';
import './App.css';
import Question from './components/Question';
import { useState, useEffect, useMemo } from 'react';
import { getRandos } from './services/questions';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { getMe, confirmAccount, doLogout, submitQuestions, getBotQuestions } from './services/users';
import LoginBox from './components/LoginBox';
import SignupBox from './components/SignupBox';
import BotQuiz from './components/BotQuiz';
import Cookies from 'universal-cookie';
import Stats from './components/Stats';
import BotDetails from './components/BotDetails';
import CircularProgress from '@mui/material/CircularProgress';

if (window.location.href.indexOf('http:') !== -1) {
  // Redirect to TLS
  const tlsUrl = window.location.href.replace('http:', 'https:');
  window.location.href = tlsUrl;
}

function App() {
  const cookies = new Cookies();
  const [selectedQuestions, setSelectedQuestions] = useState(new Array(10));
  const [showSignup, setShowSignup] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [hasBotUrl, setHasBotUrl] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [fetching, setFetching] = useState(false);
  const [fetchingQuestions, setFetchingQuestions] = useState(false);
  const [botQuestions, setBotQuestions] = useState();
  const [editing, setEditing] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [onboardingResponses, setOnboardingResponses] = useState();
  const [onboardingQuestions, setOnboardingQuestions] = useState();
  const [showStats, setShowStats] = useState(false);
  const [showQuestions, setShowQuestions] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [sessionCookie, setSessionCookie] = useState(cookies.get('lcookie'));
  const resetToken = window.location.href.match(/reset_token/) ? window.location.href.match(/reset_token=(.*)/)[1] : '';
  const botUser = window.location.pathname.match(/\/u\//)
                           ? window.location.pathname.match(/\/u\/(.*)/)[1]
                           : undefined;
  const confirmation_token = window.location.href.match(/confirmation_token/)
                           ? window.location.href.match(/confirmation_token=(.*)/)[1]
                           : '';

  useEffect(() => {
    if (!sessionCookie) {
      const cvalue = Math.random(100.999);
      cookies.set('lcookie', cvalue, {path: '/', expires: new Date(Date.now() + 86400 * 1000 * 364)});
      setSessionCookie(cvalue);
    }
    if (botUser) {
      getBotQuestions(botUser, sessionCookie).then(qs => {
        qs.json().then((bq) => {
          if (bq.error) {
            const loc = window.location.href;
            toast.error(bq.error, {onClose: () => window.location.href = '/'});
          } else {
            setBotQuestions(bq);
          }
        });
      });
    } else if (currentUser && hasBotUrl) {
      setShowStats(true);
      setShowQuestions(false);
      setEditing(false);
    } else if (currentUser) {
      setFetchingQuestions(true);
      getRandos().then(q => {
        q.json().then(data => {
          setQuestions(data.questions);
          setShowStats(false);
          setShowQuestions(true);
          setEditing(true);
          setFetchingQuestions(false);
        });
      });
    }
  }, []);

  useEffect(() => {
    // See if we're logged in.. make a ping to /me
    setFetching(true);
    if (!currentUser) {
      getMe().then(meData => {
        meData?.json().then((me) => {
          setFetching(false);
          if (me.user) {
            setCurrentUser(me.user);
            if (me.user.questions && me.user.questions.length > 0) {
              setHasBotUrl(true);
              setEditing(false);
              setQuestionsFromUser(me.user);
            } else {
              selectQs();
              setEditing(true);
            }
          } else {
            setCurrentUser();
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    if (!confirmation_token) {
      return;
    }
    confirmAccount(confirmation_token).then(data => {
      data.json().then((dj) => {
        if (dj?.user) {
          // setCurrentUser(dj.user);
          toast('Account confirmed! Welcome, ' + dj.user.display_name, {onClose: () => {
            let windowArr = window.location.href.split('?');
            windowArr.pop();
            window.location.href = windowArr[0];
          }});
        } else if (dj?.error) {
          toast.error(dj.error, {onClose: () => {
            let windowArr = window.location.href.split('?');
            windowArr.pop();
            window.location.href = windowArr[0];
          }});
        }
      });
    });
  }, [confirmation_token]);

  const clearQuestion = (question) => {
    for (let i = 0; i < questions.length; i++) {
      const q = '';
      if (selectedQuestions.map(qq => qq.body).indexOf(q) === -1) {
        let newQuestions = [...selectedQuestions];
        newQuestions[question.id] = {body: q, weight: 3, id: question.id};
        setSelectedQuestions(newQuestions);
        setEditing(true);
      }
    }
  };

  const setOnboardingResponsesWrapper = (responses) => {
    setOnboardingResponses(responses);
    setOnboardingQuestions(botQuestions);
    setBotQuestions();
  };

  const saveLiaison = async () => {
    // Validate..
    let valid = true;
    setFetching(true);
    for (let i = 0; i < selectedQuestions.length; i++) {
      if (!selectedQuestions[i].body || selectedQuestions[i].body.length < 10) {
        valid = false;
        break;
      }
      if (!selectedQuestions[i].response?.body || selectedQuestions[i].response?.body.length < 2) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      alert('Must use 10 questions and responses to create your Liaison.');
      return;
    }
    submitQuestions(selectedQuestions).then(data => {
      data?.json().then((qjs) => {
        if (hasBotUrl) {
          toast('Liaison updated!');
          setUpdated(false);
        } else {
          setUpdated(false);
          toast('Liaison created! Use the URL at the top of the page in your dating profiles to begin.');
          setHasBotUrl(true);
        }
        setEditing(false);
        setFetching(false);
      });
    });
  };

  const getSingleQuestion = () => {
    const random = Math.random(questions.length - 1) * questions.length;
    let index = Math.floor(random);
    return questions[index];
  };

  const selectQs = async () => {
    // Placeholder.. this should fetch from a proper data store.
    // Return 10 random, but unique questions.
    /*
      let rq = [];
      const desiredNumQuestions = 10;
      const countMax = 100; // 100 tries max..
      let counter = 0;
      while (rq.length < desiredNumQuestions) {
        counter++;
        if (counter < countMax) {
          const q = getSingleQuestion();
          if (rq.indexOf(q) === -1) {
            rq.push({id: rq.length, body: q, weight: 5, response: undefined});
          }
        } else {
          console.log('Warning: Hit max tries... RNG must really suck.');
        }
      }
      setSelectedQuestions(rq);
     */
    setFetchingQuestions(true);
    getRandos().then(d => {
      d.json().then(data => {
        let newQuestions = [];
        for (let i = 0; i < data.questions.length; i++) {
          newQuestions.push({id: newQuestions.length, body: data.questions[i], weight: 3, response: undefined});
        }
        setSelectedQuestions(newQuestions);
        setFetchingQuestions(false);
        setEditing(true);
      });
    });
  };

  const setQuestionsFromUser = (user=undefined) => {
    if (!user) {
      user = currentUser;
    }
    if (user?.questions?.length > 0) {
      setHasBotUrl(true);
      let newQuestionArray = new Array(10);
      for (let i = 0; i < user.questions.length; i++) {
        let newq = user.questions[i];
        newq.id = i;
        newQuestionArray[i] = newq;
      }
      setSelectedQuestions(newQuestionArray);
    }
  };

  const handleLoginResult = (data) => {
    if (data.user) {
      const back = data.user.last_sign_in_at ? ' back' : '';
      toast('Welcome' + back + ', ' + data.user.display_name + '!');
      setCurrentUser(data.user);
      if (data.user.questions && data.user.questions.length > 0) {
        setQuestionsFromUser(data.user);
      } else {
        selectQs();
      }
    } else if (data.error) {
      setCurrentUser();
      toast(data.error);
    }
  };

  const updateQuestion = (q) => {
    setUpdated(true);
    let newQuestions = [...selectedQuestions];
    newQuestions[q.id] = q;
    setSelectedQuestions(newQuestions);
  };

  return (
    <div className="App">
      <img className='logo' src={logo} alt="L I A I S O N" />
      <img className='logo-key' src={logoKey} alt="..." />
      { showQrModal &&
        <div className='qrmodal' onClick={() => setShowQrModal(false)}>
           <img style={{width: window.innerWidth - 110, marginLeft: 'auto', marginRight: 'auto'}} src={currentUser.bot_qr_url} />
        </div>
      }
      <ToastContainer />
      { !currentUser && botUser && <div className='get-yours'
                                        onClick={() => window.location.href = '/'}>Get your own LIAISON</div> }
      {
        // Have user, but not looking at someone's Liaison.
        currentUser?._id && !botUser ?
          <div className='question-container'>
            <div className='loggedin-box'>
               <span>Welcome, <b>{currentUser?.display_name}</b></span>
               <div className='logout' onClick={() => doLogout()}>
                 logout
               </div>
               { hasBotUrl && <BotDetails showQrModal={showQrModal} user={currentUser} setShowQrModal={setShowQrModal} /> }
            </div>
            { hasBotUrl &&
               <div className='tab-container'>
                 <div className={`tab-container-tab ${showStats ? 'tab-active' : 'tab-inactive'}`}
                      onClick={() => {setShowStats(true); setShowQuestions(false)}}>Stats</div>
                 <div className={`tab-container-tab ${showQuestions ? 'tab-active' : 'tab-inactive'}`}
                      onClick={() => {setShowStats(false); setShowQuestions(true)}}>Questions</div>
                 </div>
            }
            { hasBotUrl && showStats && <Stats user={currentUser} /> }

            { !hasBotUrl &&
              <>
                <div className='main-content'>
                  <b>Create your Liaison</b>.<p />
                  Liaisons are virtual dating assistants, powered by AI, designed to interview
                  individuals who are interested in meeting you. When there is a close enough
                  match, you'll be informed of their details via the method of your choice.
                </div>
                <div className='help-content'>
                  Choose ten questions. Feel free to edit them. <p style={{marginTop: '-10px'}} />When you are finished, you
                  will be given a unique URL and QR code that you can use in your dating
                  profiles to direct users to your Liaison.
                </div>
                { fetchingQuestions ? <div style={{color: '#5060f0', marginTop: '8px'}}>Generating questions... <CircularProgress /></div> : '' }
              </>
            }

            { showQuestions && !showStats && selectedQuestions.map((x) => <Question editing={editing} key={`q${x.id}`}
                                                                                    updateQuestion={updateQuestion}
                                                                                    clearQuestion={clearQuestion} question={x} />)
            }

            <div className='button-container'>
              { editing && !showStats ?
                <>
                  <div className='base-button hitme-button' onClick={() => selectQs()}>
                    { fetchingQuestions ? <CircularProgress /> : 'Hit me with 10 new questions' }
                  </div>
                  <div className='base-button' onClick={() => {setQuestionsFromUser(); setEditing(false); setUpdated(false)}}>
                    Cancel
                  </div>
                </>
                : !showStats ? <div className='base-button' onClick={() => setEditing(true)}>
                  Edit
                </div> : ''
              }
              { hasBotUrl && updated &&
                  <div className='base-button' onClick={() => saveLiaison()}>
                    { fetching ? <CircularProgress /> : 'Update Liaison' }
                  </div>
              }
              { !hasBotUrl &&
                  <div className='base-button' onClick={() => saveLiaison()}>
                    { fetching ? <CircularProgress /> : 'Create Liaison' }
                  </div>
              }
            </div>
          </div>
        :
         (showSignup || onboardingResponses) ?
            <SignupBox onboardingResponses={onboardingResponses} onboardingQuestions={onboardingQuestions ? onboardingQuestions['user']['questions'] : ''} />
            : (fetching === false && !botUser) && !confirmation_token
              && <LoginBox resetToken={resetToken} showSignUp={setShowSignup}
                           handleLoginResult={handleLoginResult} />
      }
      {
        botUser && botQuestions && <BotQuiz session={sessionCookie} questions={botQuestions['user']['questions']}
                                           user={botQuestions['user']} currentUser={currentUser}
                                           setOnboardingResponses={(responses) => setOnboardingResponsesWrapper(responses)} />
      }
    </div>
  );
}

export default App;
