import React, { useState, useRef } from 'react';
import { resetPassword, submitNewPassword } from '../services/users';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';

const LoginBox = ({handleLoginResult, showSignUp, resetToken}) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [passBar, setPassBar] = useState();
  const [confirmBar, setConfirmBar] = useState();
  const [passScore, setPassScore] = useState(0);

  const handleLogin = async () => {
    try {
      const email = emailRef?.current?.value;
      const pass = passwordRef?.current?.value;
      let fd = new FormData();
      fd.append('email', emailRef?.current?.value);
      fd.append('password', passwordRef?.current?.value);
      fetch('/api/v1/users/login', {body: fd, method: 'POST'}).then(loginData => {
        if (loginData.status === 401) {
          handleLoginResult({error: 'Login or password incorrect.'});
          return;
        }
        loginData.json().then((jsonData) => {
          if (loginData.status === 422) {
            handleLoginResult(jsonData);
            return;
          } else {
            handleLoginResult(jsonData);
          }
        });
      });
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  const resetPasswordWrapper = () => {
    resetPassword({email: emailRef?.current?.value});
    toast('Recover password link sent to: ' + emailRef?.current?.value);
  };

  const submitNewPasswordWrapper = () => {
    if (passwordConfirmRef?.current.value !== passwordRef?.current.value) {
      toast.error('Passwords do not match.');
      return;
    }
    const result = submitNewPassword({reset_token: resetToken, newpass: passwordConfirmRef?.current.value})
    if (result.status === 422) {
      toast.error('Invalid password reset token.');
    } else {
      toast('Password reset!', {onClose: (d) => window.location.href = '/'});
    }
  };

  return (
    <div className='login-box'>
      <div className='login-input'>
        { !resetToken &&
        <div style={{width: '320px', padding: '8px'}}>
          <div className='input-label' style={{width: '68px'}}>email</div>
          <div className='input-wrapper'>
            <input className='input-input' ref={emailRef} />
          </div>
        </div>
        }
        { !forgotPassword &&
          <div style={{width: '320px', padding: '8px'}}>
            <>
              <div className='input-label' style={{width: '88px'}}>passwd</div>
              <div className='input-wrapper'>
                 <input type='password' className='input-input' ref={passwordRef}
                        onChange={(data) => setPassBar(data.target.value)} />
                 { resetToken && <div><PasswordStrengthBar style={{width: '206px', marginLeft: '4px'}}
                                                           onChangeScore={(score) => setPassScore(score)} minLength={8}
                                                           password={passBar} /></div> }
              </div>
            </>
            { resetToken &&
              <>
                <div className='input-label' style={{width: '85px'}}>confirm</div>
                <div className='input-wrapper'>
                   <input type='password' className='input-input' ref={passwordConfirmRef}
                          onChange={(data) => setConfirmBar(data.target.value)} />
                   { resetToken && <div><PasswordStrengthBar style={{width: '206px', marginLeft: '4px'}}
                                                             onChangeScore={(score) => setPassScore(score)} minLength={8}
                                                             password={confirmBar} /></div> }
                </div>
              </>
            }
            { !resetToken && <div style={{marginRight: '34px'}}><span style={{fontSize: '16px', cursor: 'pointer', color: '#1185a2'}}
                                   onClick={() => setForgotPassword(true)}>Forgot password?</span></div> }
          </div>
        }
      </div>
      <div className='login-right-side'>
         <span style={{fontSize: '20px'}}>Don't have an account? </span>
         <br />
         <div style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => showSignUp(true)}>
           <span style={{textDecoration: 'underline'}}>Sign Up!</span>
         </div>
         <div onClick={() => {forgotPassword ? resetPasswordWrapper() : resetToken ? submitNewPasswordWrapper() : handleLogin()}}
              className='base-button signin-button'>{(forgotPassword || resetToken) ? "Reset" : "Log in"}</div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoginBox;
