import React, { useState, useRef } from 'react';
import login from '../services/users';
import PasswordStrengthBar from 'react-password-strength-bar';
import {toast, ToastContainer} from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const SignupBox = ({showSignUp, onboardingQuestions, onboardingResponses}) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmRef = useRef(null);
  const nameRef = useRef(null);
  const [passScore, setPassScore] = useState();
  const [barPass, setBarPass] = useState();
  const [barConfirm, setBarConfirm] = useState();
  const [submitting, setSubmitting] = useState(false);

  const handleSignup = () => {
    let error = false;
    if (!emailRef?.current?.value) {
      toast.error('Must specify an email address.');
      error = true;
    }
    if (passScore < 2) {
      toast.error('Password not strong enough.');
      error = true;
    }
    if (passwordRef?.current?.value !== confirmRef?.current?.value) {
      toast.error('Passwords do not match.');
      error = true;
    }
    if (error) {
      return;
    }
    try {
      setSubmitting(true);
      const email = emailRef?.current?.value;
      const pass = passwordRef?.current?.value;
      let regData = new FormData();
      regData.append('display_name', nameRef?.current?.value);
      regData.append('email', emailRef?.current?.value);
      regData.append('password', passwordRef?.current?.value);
      regData.append('password_confirmation', confirmRef?.current?.value);
      if (onboardingQuestions && onboardingResponses) {
        for (let i = 0; i < onboardingQuestions.length; i++) {
          regData.append('questions[]', onboardingQuestions[i].body);
        }
        for (let i = 0; i < onboardingResponses.length; i++) {
          regData.append('responses[]', onboardingResponses[i].response);
        }
      }
      fetch('/api/v1/users', {body: regData, method: 'POST'}).then(userData => {
        userData.json().then((dj) => {
          if (dj && dj.user) {
            toast('User registered. Email sent for confirmation.', {onClose: () => window.location.href = '/'});
          } else if (dj.error) {
            console.log(' Error here is: ' + dj.error);
            toast.error(dj.error);
            setSubmitting(false);
          }
        });
      });
    } catch (e) {
      console.log("Error: " + e);
      toast.error(e);
      setSubmitting(false);
    }
  }

  return (
    <div className='login-box'>
      <ToastContainer />
      <div className='login-input'>
        { onboardingResponses && <span style={{color: '#5070f0'}}>Your Liaison is ready. Create an account to claim it!</span> }
        <div style={{width: '290px', padding: '8px'}}>
          <div className='input-label' style={{width: '70px'}}>email</div>
          <div className='input-wrapper'>
            <input className='input-input' ref={emailRef} />
          </div>
        </div>
        <div style={{width: '290px', padding: '8px'}}>
          <div className='input-label' style={{width: '132px'}}>display name</div>
          <div className='input-wrapper'>
            <input className='input-input' ref={nameRef} />
          </div>
        </div>
        <div style={{width: '290px', padding: '8px'}}>
          <div className='input-label' style={{width: '100px'}}>password</div>
          <div className='input-wrapper'>
            <input onChange={(data) => setBarPass(data.target.value)}
                   type='password' className='input-input' ref={passwordRef} />
            <PasswordStrengthBar onChangeScore={(score) => setPassScore(score)}
                                 style={{marginLeft: '3px'}}
                                 minLength={8} password={barPass} />
          </div>
        </div>
        <div style={{width: '290px', padding: '8px', marginTop: '-20px'}}>
          <div className='input-label' style={{width: '80px'}}>confirm</div>
          <div className='input-wrapper'>
            <input onChange={(data) => setBarConfirm(data.target.value)}
                   type='password' className='input-input' ref={confirmRef} />
            <PasswordStrengthBar onChangeScore={(score) => setPassScore(score)}
                                 style={{marginLeft: '3px'}}
                                 minLength={8} password={barConfirm} />
          </div>
        </div>
      </div>
      <div className='login-right-side'>
         <span style={{fontSize: '18px'}}>You will be sent an email message for confirmation. </span>
        { submitting ? <CircularProgress sx={{marginLeft: '45px', marginTop: '18px'}} size={48} color='inherit' />
          : <div className='base-button signin-button' onClick={() => handleSignup()}>Submit</div>
         }
      </div>
    </div>
  );
}

export default SignupBox;
