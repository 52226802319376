import React, { useState, useRef } from 'react';

const Question = ({clearQuestion, question, updateQuestion, editing}) => {
  // const [q, setQ] = useState(options['question']);
  // const [a, setA] = useState();
  const inputRef = useRef(null);
  const responseRef = useRef(null);
  const weightRef = useRef(null);

  const handleChange = () => {
     let updatedQuestion = {
       id: question.id,
       response: {body: responseRef?.current?.value},
       weight: weightRef?.current?.value,
       body: inputRef?.current?.value
     };
     updateQuestion(updatedQuestion);
  };

  return (
    <div className='question'>
      <div className='question-head'>
        <div className='xbutton' onClick={() => clearQuestion(question)}>x</div>
        <textarea disabled={editing ? '' : 'disabled'} ref={inputRef} className='question-input' type='text'
                  value={question ? question['body'] : ''} onChange={handleChange} />
      </div>
      <div className='response'>
        <div style={{display: 'flex', paddingRight: '8px', fontSize: '26px'}}>Prio:</div>
        <div style={{display: 'flex', height: '25px', paddingRight: '6px', fontSize: '26px'}}>
          <select disabled={editing ? '' : 'disabled'} style={{fontSize: '26px', border: 'none', height: '34px'}} ref={weightRef} onChange={handleChange}>
            <option selected={question.weight === 5} value='5'>5 (High)</option>
            <option selected={question.weight === 4} value='4'>4</option>
            <option selected={question.weight === 3 || !question.weight} value='3'>3</option>
            <option selected={question.weight === 2} value='2'>2</option>
            <option selected={question.weight === 1} value='1'>1 (Low)</option>
          </select>
        </div>
        <div style={{display: 'flex'}}>
          <textarea disabled={editing ? '' : 'disabled'} ref={responseRef} className='question-response' type='text'
                    value={question?.response ? question?.response?.body : ''}
                    placeholder={'Your answer'} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
}

export default Question;
