import React, { useState, useRef } from 'react';

const Stats = ({user}) => {
  // {"responses":326,"match_average":7.2,"match_max":19,"last_match":"2024-04-13T23:13:28.512Z"}
  return (
    <div className='stats'>
      <div style={{backgroundColor: '#1185a2', padding: '5px', color: '#ffffff'}}>Stats</div>
      <div className='stats-row'>
        <div className='stats-label'>
          Responses
        </div>
        <div className='stats-value'>
          {user.stats['responses'] || '0'}
        </div>
      </div>
      <div className='stats-row'>
        <div className='stats-label'>
          Matches
        </div>
        <div className='stats-value'>
          {user.stats['matches'] || "0"}
        </div>
      </div>
      <div className='stats-row'>
        <div className='stats-label'>
          Match Score Max
        </div>
        <div className='stats-value'>
          {user.stats['match_max'] || '0'}%
        </div>
      </div>
      <div className='stats-row'>
        <div className='stats-label'>
          Match Score Average
        </div>
        <div className='stats-value'>
          {user.stats['match_average'] || '0'}%
        </div>
      </div>
      <div className='stats-row'>
        <div className='stats-label'>
          Last Match
        </div>
        <div className='stats-value'>
          <i>{user.stats['last_match'] && new Date(user.stats['last_match']).toLocaleString()}</i>
        </div>
      </div>
    </div>
  );
}

export default Stats;
