import React, { useState } from 'react';
import copyIcon from '../images/content_copy.svg';

const BotDetails = ({user, setShowQrModal, showQrModal}) => {
  const [copyString, setCopyString] = useState();

  return (
    <div className='your-liaison-details'>
      <div className='your-liaison-details-label'>Your Liaison Details</div>
        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer'}}
             onClick={() => {
             setCopyString('Copied!');
             navigator.clipboard.writeText(`https://liaisons.chat/u/${encodeURI(user?.display_name)}`)}}>
          <i>{copyString}</i>
          <img className='copy-icon' src={copyIcon} />
          <span style={{fontWeight: 'bold', color: '#1185a2'}}>
            {`liaisons.chat/u/${encodeURI(user?.display_name)}`}
          </span>
        </div>
      { user?.bot_qr_url &&
         <>  
           <div onClick={() => showQrModal ? setShowQrModal(false) : setShowQrModal(true)} className='base-button qr-button'>
             QR Code
           </div>
         </> 
      }   
      </div>
  );
}

export default BotDetails;
