import React from 'react';

const ProgressIndicator = ({value, result}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', marginTop: '14px'}}>
      <div style={{display: 'flex', paddingRight: '8px', paddingTop: '4px'}}></div>
      <div style={{paddingRight: '5px', marginTop: '3px', fontSize: '20px'}}>{ result && `Match: ${value}%` }</div>
      <div className='progress-indicator'>
        <div style={{width: `${value * 2}px`, height: '18px', backgroundColor: result ? '#5060ef' : '#ff6050'}} />
      </div>
    </div>
  );
}

export default ProgressIndicator;
