export const getMe = async() => {
  try {
    const me = await fetch('/api/v1/users/me');
    return me;
  } catch(e) {
    return {error: e};
  };
};

export const resetPassword = ({email}) => {
  const resetUrl = `/api/v1/users/me/password_reset?email=${email}`;
  try {
    fetch(resetUrl, {method: 'POST'}, (err, data) => {
      if (data) {
        let parsedBody = JSON.parse(data.body);
      } else {
        console.log('[resetPassword]: Got nothing: err is: ' + err);
      }
    });
  } catch(e) {
    console.log('[resetPassword] Reset call unsuccessful: ' + e);
  }
}

export const submitNewPassword = async ({reset_token, newpass}) => {
  const resetUrl = '/api/v1/users/password_update';
  let fd = new FormData();
  fd.append('user[newpassword]', newpass);
  fd.append('user[reset_token]', reset_token);
  let requestData = {
    uri: '/api/v1/users/me',
    method: 'POST',
    body: fd,
  };
  const response = await fetch(resetUrl, requestData);
  return response;
}

export const doLogout = async() => {
  try {
    const logoutResults = await fetch('/api/v1/users/logout', {method: 'DELETE'});
    if (logoutResults.status === 204) {
      window.location.reload();
    } else {
      return {error: logoutResults};
    }
  } catch (e) {
    return {error: e};
  }
};

export const confirmAccount = async (token) => {
  try {
    let confirmData = new FormData();
    confirmData.append('confirmation_token', token);
    const dj = await fetch('/api/v1/users/confirmation', {body: confirmData, method: 'POST'});
    return dj;
  } catch (e) {
    console.log("Error: " + e);
    return {error: e};
  }
};

export const getBotQuestions = async (botUser, session=undefined) => {
console.log('botUser: ' + botUser);
  try {
    let url = '/api/v1/questions';
    if (botUser) {
      url += '?bot_user=' + botUser;
    }
    if (session) {
      url += '&session=' + session;
    }
    const dj = await fetch(url);
    return dj;
  } catch (e) {
    return {error: e};
  }
};

export const submitResponses = async(responses, session) => {
  try {
    let rdata = new FormData();
    for (let i = 0; i < responses.length; i++) {
      rdata.append('responses[][response]', responses[i]['response']);
      rdata.append('responses[][question_id]', responses[i]['question_id']);
    }
    rdata.append('session', session);
    const url = '/api/v1/questions';
    const method = 'PUT';
    const dj = await fetch(url, {body: rdata, method: method});
    return dj;
  } catch(e) {
    console.log('  ERROR: ' + e);
    return {error: e};
  }
};

export const submitQuestions = async (questions) => {
  try {
    console.log('Got submit questions... ');
    let questionData = new FormData();
    for (let i = 0; i < questions.length; i++) {
      questionData.append('questions[][body]', questions[i]['body']);
      questionData.append('questions[][weight]', questions[i]['weight']);
      if (questions[i]['response'] && questions[i]['response'] !== 'undefined') {
        questionData.append('questions[][response]', questions[i]['response']['body']);
      }
    }
    const dj = await fetch('/api/v1/users/me/questions', {body: questionData, method: 'PUT'});
    return dj;
  } catch (e) {
    return {error: e};
  }
};

export const submitMatch = async (matchId, userId, contact, notify) => {
  try {
    let mData = new FormData();
    mData.append('contact', contact);
    const dj = await fetch(`/api/v1/users/${userId}/match/${matchId}?notify=${notify}`, {body: mData, method: 'POST'});
    return dj;
  } catch (e) {
    return {error: e};
  }
};
