

export const getRandos = async() => {
  try {
    const questions = await fetch('/api/v1/questions/randos');
    return questions;
  } catch(e) {
    return {error: e};
  };
};

export const getQuestions = () => {
  const questions = [
    "What are your important life values?",
    "Do you believe in giving people second chances?",
    "Who are the people you consider the most important in your life?",
    "Do you know how to keep a secret?",
    "Do you have close friends and acquaintances with whom you discuss personal issues?",
    "How would your close friends describe you?",
    "What experience shaped your mindset and made you who you are today?",
    "Do you like to sort issues out on your own, or do you prefer to seek help from people?",
    "What is your favorite movie genre?",
    "What is your favorite music genre?",
    "What types of books do you like reading?",
    "Do you make decisions instantly, or do you take time to think through them?",
    "How do you think you can change the world in your little way?",
    "What are you most grateful for presently?",
    "What is your preferred vacation experience?",
    "What is your stand on taking substances like alcohol and drugs?",
    "Are you open to eating out, and what is your preferred type of restaurant?",
    "What would you love to change about your past?",
    "What do you do when you need inspiration?",
    "What is that thing that you will never change about yourself?",
    "With these compatibility questions on intimacy, you can know if you can work something out or not.",
    "What is your love language?",
    "What are your expectations or concerns concerning sex?",
    "Will you open up if you are not satisfied sexually?",
    "What do you love most about sex?",
    "Do you feel masturbation is cool or healthy?",
    "Have you ever doubted your sexuality?",
    "What turns you on when it comes to your partner?",
    "What are your limits when it comes to sex?",
    "Can you trust a partner with your sexual fantasies?",
    "What is your preferred sexual style?",
    "What is your preferred conflict style?",
    "How do you show it if you are angry?",
    "What part of me annoys you the most?",
    "If we had an intense disagreement, how do you think we would be able to resolve it?",
    "When we have heated issues, will you involve a third party?",
    "What is the longest you would go without talking to your partner when you are angry?",
    "Does your ego prevent you from apologizing when you are wrong?",
    "If you have feelings for someone outside our relationship, will you inform me?",
    "What are your limitations to intimacy between the both of us?",
    "Has there been a time when you felt so loved and connected in our relationship?",
    "What is your view on having a relationship counselor?",
    "If you feel that you’re being taken for granted, will you be able to tell your partner?",
    "What does commitment mean to you, and what actions do you want to see in light of this?",
    "What is the most romantic idea you have ever envisaged in this relationship?",
    "What is the main reason for wanting to get married, and why do you want to marry me?",
    "Do you have a good relationship with your exes?",
    "Do you think online dating is cool?",
    "What was the first thing that attracted you to me?",
    "Where do you see us in the next 20 years?",
    "What is the deal-breaker for you in this relationship?",
    "What are the habits you will most likely give up when you get married and start living together?",
    "Is there any habit or attitude you want me to change before we get married?",
    "What kind of partner do you want to be in this relationship?",
    "How often do you desire to be alone, and how can I play my part?",
    "What is your ideal definition of support, and how do you expect it from me?",
    "What is the one thing that can make you insecure?",
    "What attachment style do you have?",
    "What is your view on pornography?",
    "Do you feel that couples need some space from each other to be happier?"
  ];
  return questions;
};
