import React, { useState, useRef, useEffect } from 'react';
import ProgressIndicator from './ProgressIndicator';
import { submitResponses, submitMatch } from '../services/users';
import dots from '../images/dots.gif';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BotQuiz = ({questions, session, user, currentUser, setOnboardingResponses}) => {
  const [qnum, setQnum] = useState(0);
  const [responses, setResponses] = useState([])
  const [obResponses, setObResponses] = useState();
  const [progress, setProgress] = useState();
  const [currentResponse, setCurrentResponse] = useState('');
  const [matchId, setMatchId] = useState();
  const [summary, setSummary] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [notify, setNotify] = useState('on');
  const [fullReport, setFullReport] = useState('');
  const [fetching, setFetching] = useState(false);
  const [showFinalMatch, setShowFinalMatch] = useState(false);
  const [hasContactContent, setHasContactContent] = useState('');
  const cref = useRef();
  const finalCref = useRef();

  useEffect(() => {
    cref.current?.focus();
    finalCref.current?.focus();
  });

  const submitMyMatch = async (notify) => {
    try {
      await setFetching(true);
      const result = await submitMatch(matchId, user._id, cref?.current.value, notify);
      await setHasContactContent('');
      await setSubmitted(true);
      await setSummary('Thank you for your submission! We have notified ' + user.display_name + ' with your results.');
      await setShowFinalMatch(true);
      await setFetching(false);
      if (!result.error && result.match) {
        toast('Match submitted!');
      }
    } catch (e) {
      console.log('Error: ' + e);
      return {error: e};
    }
  };

  const recordResponse = async () => {
    setFetching(true);
    let newResponses = [...responses];
    newResponses.push({response: currentResponse, question_id: questions[qnum]._id});
    await setResponses(newResponses);
    let newNum = new Number(qnum);
    newNum += 1;
    if (qnum === 9) {
      // Time to send the results...
      const sResults = await doSubmit(newResponses);
      sResults.json().then(async data => {
        await setProgress(data.score);
        if (data.match) {
          await setMatchId(data.match._id);
        }
        setSummary('Quiz complete!');
        // await setObResponses(responses);
        await setObResponses(newResponses);
        await setResponses([]);
        await setFetching(false);
        if (data.full_report) {
          await setFullReport(data.full_report);
        }
      });
    } else if (qnum < 9) {
      await new Promise(r => setTimeout(r, 2000));
      setFetching(false);
    }
    setQnum(newNum);
    setCurrentResponse('');
  };

  const doSubmit = async (resps) => {
    const rdata = await submitResponses(resps, currentUser?._id || session);
    return rdata;
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCurrentResponse(e.target.value);
  }

  const phrases = [
    'Giving this some thought. May take a little time',
    'Let me ponder this for a moment',
    'Calculating match score, and trying to remember if I fed the dog.',
    'Some things to think about. Please wait.',
    'Well that gives me a lot to go on. Let me combine your personalities and see what happens',
    'Please be patient. Dynamite comes in small packages. Wait, wrong expression. Just a moment.',
    'Thinking, this may take a moment.',
    'Well that certainly makes for an interesting match. Let me see how this works out. One moment please.',
    'Thinking',
    'Pondering'
  ];

  return (
    <div className='bot-question-container'>
      { (!summary && qnum < 10 && questions) ?
        <>
          <div style={{fontSize: '30px', padding: '10px', color: '#303030', borderRadius: '6px', borderBottom: 'solid 1px #cccccc'}}>
            Hi there! <b>{user['display_name']}</b> would like to know more about you.
          </div>
          <p />
          { qnum > 0 && <ProgressIndicator value={qnum * 10} /> }
          <p />
          { fetching ?
              <>
                <div style={{paddingTop: '10px', color: '#1185a2'}}>{ phrases.sort(function() {return 0.5 - Math.random()})[0] }</div>
                <img style={{width: '120px'}} src={dots} />
              </>
            :
              <div className='bot-question'>
                {questions && questions[qnum] && questions[qnum]['body']}
              </div>
          }
          <div className='bot-question-response'>
            <textarea ref={finalCref} value={currentResponse} onChange={(e) => handleChange(e)}
                      disabled={fetching ? true : false} className='final-text-input' placeholder='Your response'>
            </textarea>
          </div>
          {
            !fetching && currentResponse !== '' &&
              <div className='base-button bot-response-button' onClick={() => recordResponse()}>
                 Submit
              </div>
          }
        </>
      :
        <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center'}}>
          <div><b>{summary}</b></div>
          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center'}}>
            { showFinalMatch && <ProgressIndicator result={true} value={progress} /> }
          </div>
          <div style={{display: 'flex', flexDirection: 'row', fontSize: '18px', marginTop: '8px', padding: '12px'}}>
            <input type='checkbox' id='notify' checked={notify === 'on' ? true : false} onChange={(e) => setNotify(e.target.value)} />
            <label for='notify'>Share my answers</label>
          </div>
          <div style={{marginTop: '10px', alignSelf: 'center', marginBottom: '10px', display: 'flex', flexDirection: 'column'}}>
            <textarea disabled={submitted ? true : false} onChange={() => setHasContactContent(true)} ref={cref}
                      style={{fontSize: '24px', width: '480px', minHeight: '200px', borderRadius: '8px', padding: '12px'}}
                      placeholder='Write a little bit about yourself, and how you may be contacted.'>
            </textarea>
          </div>
          { hasContactContent !== '' && fetching && <img style={{width: '120px'}} src={dots} /> }
          { hasContactContent !== '' && !fetching && <div onClick={() => submitMyMatch(notify)} className='base-button submit-match-button'>Submit</div> }
          { submitted && summary && !currentUser &&
             <div className='fast-onboard-container'>
               Now that we've learned a little bit about you, would you like to create your own Liaison?
               <div className='fast-onboard-button'
                    onClick={() => setOnboardingResponses(obResponses)}>
                 Yes!
               </div>
             </div>
           }
        </div>
      }
      <ToastContainer />
    </div>
  );
}

export default BotQuiz;
